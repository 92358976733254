<script>
	import { onMount } from "svelte";
	import Carousel from "./Carousel.svelte";
	import url from "./url";
	import { filters } from "./store";
	import { environment } from "./environment";

	export let shopId = 68141220126;
	export let localStorageItemName = "uturn-filters";
	export let returnsCollectionName = "new-from-returns";
	export let filterName = "option";
	export let itemsPerPage = 3;
	export let hideParentBlock = true;

	let returnLabel = "Re:Turn";
	let placement = "collection_carousel";

	let component;
	let items = [];
	let show = false;
	let hasLoaded = false;
	let width;

	let innerHeight;
	let innerWidth;

	let perPage = 3;
	let isMobile = false;

	let uturnOptions = [];
	let hasParams = false;

	let inReturnsCollection = false;

	$: innerWidth, innerHeight, updateViewport();

	$: $url, getUrlParams(), hideInReturnCollections();

	$: show, hasLoaded, shouldHideParentBlock();

	onMount(async () => {
		await getPlacements();
		await getOptions();
		getUrlParams();
	});

	function hideInReturnCollections() {
		if (window.location.href.includes(returnsCollectionName)) {
			inReturnsCollection = true;
		} else {
			inReturnsCollection = false;
		}
	}

	async function getPlacements() {
		const ls = localStorage.getItem("uturn-placements");
		const storageData = JSON.parse(ls == "" || ls == null ? "{}" : ls);
		let json;

		if (storageData.date && Date.now() - storageData.date < 300000) {
			json = storageData;
		} else {
			const response = await fetch(
				`${environment.shopGateUrl}/shop/placements/${shopId}`
			);
			json = await response.json();
			const storageObject = {
				...json,
				date: Date.now(),
			};
			localStorage.setItem(
				"uturn-placements",
				JSON.stringify(storageObject)
			);
		}

		show = false;
		returnLabel = json?.data.return_label;
		json?.placements.forEach((element) => {
			if (element.placement == placement) {
				if (element.show == 1) {
					show = true;
				}
				return;
			}
		});
	}

	async function getOptions() {
		const response = await fetch(
			`${environment.shopGateUrl}/shop/options?retailerid=${shopId}`
		);
		const json = await response.json();
		uturnOptions = json;
	}

	function getData() {
		const url = getUrl();
		hasLoaded = false;
		if (!hasParams) {
			return;
		}
		fetch(url)
			.then((resp) => resp.json())
			.then((data) => {
				items = [];
				items = data.filter((x) => x.product_image_url !== "");
				if (data.length > 0) {
					setTimeout((hasLoaded = true), 1000);
				} else {
					hasLoaded = false;
				}
			});
	}

	function shouldHideParentBlock() {
		if (!hideParentBlock || !component) {
			return;
		}
		if (!show || !hasLoaded) {
			component.parentNode.parentNode.style.display = "none";
		} else {
			component.parentNode.parentNode.style.display = "block";
		}
	}

	function getUrl() {
		hasParams = false;
		let url = `${environment.shopGateUrl}/utengine/featuredproducts/${shopId}`;

		const ls = localStorage.getItem(localStorageItemName);
		var filterData = JSON.parse(ls == "" || ls == null ? "{}" : ls);

		if (filterData.options && filterData.options.length > 0) {
			hasParams = true;
			url += "?options=";
			filterData.options.forEach((option) => {
				option.values.forEach((value) => {
					url += `${value}|`;
				});
			});
			url = url.substring(0, url.length - 1);
		}
		return url;
	}

	function goTo(item) {
		window.open(item.product_url, "_self");
	}

	function updateViewport() {
		hasLoaded = false;
		items = [...items];
		if (innerHeight > innerWidth) {
			perPage = 1;
			width = 240;
			isMobile = true;
		} else {
			perPage = itemsPerPage;
			const componentWidth = innerWidth - 96;
			const spacebetween = perPage * (componentWidth / 12);
			width = (componentWidth - spacebetween) / perPage;
			isMobile = false;
		}
		if (items.length > 0) {
			hasLoaded = true;
		}
	}

	function getUrlParams() {
		const searchUrl = window.location.search;
		let localStorageItems = {
			collection: "",
			options: [],
		};
		let optionValues = [];

		let optionParamSubstring = "";

		let searchUrlIndexStart = searchUrl.indexOf(filterName);
		if (searchUrlIndexStart > 0) {
			optionParamSubstring = searchUrl.substring(
				searchUrlIndexStart + filterName.length + 1
			);
		}

		let optionParamEndIndex = 1;

		if (optionParamSubstring == "" && uturnOptions.length > 0) {
			const urlParams = new URLSearchParams(window.location.search);
			uturnOptions.forEach((uturnOption) => {
				const urlParamValues = urlParams.getAll(
					uturnOption.option_name.toLowerCase()
				);
				if (urlParamValues) {
					urlParamValues.forEach((paramValue) => {
						optionValues.push({
							option_name: uturnOption.option_name.toLowerCase(),
							value: paramValue,
						});
					});
				}
			});
		} else {
			while (optionParamEndIndex > 0) {
				optionParamEndIndex = optionParamSubstring.indexOf("=");
				if (optionParamEndIndex < 0) {
					break;
				}
				let optionParamName = optionParamSubstring.substring(
					0,
					optionParamEndIndex
				);

				optionParamSubstring = optionParamSubstring.substring(
					optionParamEndIndex + 1
				);

				let optionValueEndIndex = optionParamSubstring.indexOf("&");
				let optionValue = "";
				if (optionValueEndIndex > 0) {
					optionValue = optionParamSubstring.substring(
						0,
						optionValueEndIndex
					);
				} else {
					optionValue = optionParamSubstring;
				}

				optionValues.push({
					option_name: optionParamName,
					value: optionValue,
				});

				let optionStartIndex = optionParamSubstring.indexOf(filterName);
				if (optionStartIndex < 0) {
					break;
				}
				optionParamSubstring = optionParamSubstring.substring(
					optionStartIndex + filterName.length + 1
				);
			}
		}

		const href = window.location.href;

		let collectionName = "";

		const firstIndex = href.lastIndexOf("/") + 1;
		collectionName = href.substring(firstIndex);
		const lastIndex = collectionName.indexOf("?");
		if (lastIndex > 0) {
			collectionName = collectionName.substring(0, lastIndex);
		}
		localStorageItems.collection = collectionName;

		optionValues.forEach((element) => {
			var exists = null;
			if (localStorageItems.options.length > 0) {
				exists = localStorageItems.options.find(
					(x) => x.option_name == element.option_name
				);
			}

			if (exists) {
				exists.values.push(element.value);
			} else {
				localStorageItems.options.push({
					option_name: element.option_name,
					values: [element.value],
				});
			}
		});

		filters.set(JSON.stringify(localStorageItems));

		if (returnsCollectionName == localStorageItems.collection) {
			return;
		}
		if (show) {
			getData();
		}
	}
</script>

<svelte:window bind:innerHeight bind:innerWidth />
<div bind:this={component}>
	{#if show && hasLoaded}
		<div class="uturn-featured-collection-container">
			<Carousel
				dots={false}
				loop={false}
				controls={!isMobile}
				threshold={100}
				duration={1100}
				bind:perPage
			>
				{#each items as item}
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<div
						class="uturn-featured-collection-item"
						style="width:{width}px"
					>
						<span
							class="product-label uturn uturn-featured-collection-label"
						>
							{returnLabel}
						</span>
						<img
							on:click|self={() => goTo(item)}
							class="uturn-featured-collection-image"
							src={item.product_image_url}
							alt=""
						/>
						<div>{item.shop_product_title}</div>
						<div>
							${item.shop_price.toFixed(2)}
							<span class="original-price"
								>${item.shop_original_price.toFixed(2)}</span
							>
						</div>
					</div>
				{/each}
				<div slot="left-control">
					<!-- svelte-ignore a11y-missing-attribute -->
					<div
						class="swiper-button-prev swiper-button-disabled"
						aria-label="Previous slide"
						tabindex="0"
						role="button"
						aria-disabled="true"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
						>
							<path
								d="M0-.25H24v24H0Z"
								transform="translate(0 0.25)"
								style="fill:none"
							/>
							<polyline
								points="14.4 7.03 9 12.43 14.4 17.83"
								style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-miterlimit:8;stroke-width:2px"
							/>
						</svg>
					</div>
				</div>
				<div slot="right-control">
					<!-- svelte-ignore a11y-missing-attribute -->
					<div
						class="swiper-button-next"
						aria-label="Next slide"
						tabindex="0"
						role="button"
						aria-disabled="false"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
						>
							<path
								d="M0-.25H24v24H0Z"
								transform="translate(0 0.25)"
								style="fill:none"
							/>
							<polyline
								points="10 17.83 15.4 12.43 10 7.03"
								style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-miterlimit:8;stroke-width:2px"
							/>
						</svg>
					</div>
				</div>
			</Carousel>
		</div>
	{/if}
</div>

<style>
	.uturn-featured-collection-container {
		margin: 0;
	}

	.uturn-featured-collection-image {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.uturn-featured-collection-item {
		display: block;
		position: relative;
		margin: 16px 0px 16px 72px;
		width: 100%;
		cursor: pointer;
	}

	.uturn-featured-collection-label {
		position: absolute;
		top: 20px;
		left: -20px;
	}

	.original-price {
		text-decoration: line-through;
		opacity: 0.5;
	}
</style>
